import React from 'react';
import Screenshot from '../Components/Screenshot';
import Back from '../Components/Back';
import boss1 from '../media/boss-machine-1.png';
import boss2 from '../media/boss-machine-2.png';
import boss3 from '../media/boss-machine-3.png';



function Boss() {
    return (
        <div className="p-3">
            <h1 className="font-bold text-2xl mb-3 py-1">Boss Machine project</h1>
            <h2 className="font-bold text-xl mb-3 py-1">General information</h2>
            <p className="py-1">
                For this project I had to create an API to serve information to a 'Boss Machine', a unique management application for today’s most accomplished (evil) entrepreneurs.
            </p>
            <p className="py-1">
                Provided with a React/Redux front-end by Codecademy, I was tasked with using Node.js and Express to create the API endpoints to manage (CRUD) ‘minions’, ‘million dollar ideas’, and meetings that are continually added to a schedule.
            </p>
            <h2 className="font-bold text-xl mb-3 py-1">Technologies</h2>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">JavaScript</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Node.js</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Express</span>
            <h2 className="font-bold text-xl mb-3 py-1">Links</h2>
            <p className="py-1 pb-2">This project runs locally, so is not currently available for live preview.</p>
            <a className="inline-block bg-indigo-900 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2" href="https://github.com/vacb/codecademy-boss-machine" target="_blank" rel="noreferrer">View code on GitHub</a>
            
            <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">  
                <Screenshot src={boss1} alt="boss machine screenshot"/>
                <Screenshot src={boss2} alt="boss machine screenshot"/>
                <Screenshot src={boss3} alt="boss machine screenshot"/>
            </div>
        
            <Back />
        </div>
    )
}

export default Boss;