import React from 'react';
import { Link } from 'react-router-dom';

function NavigationMenu(props) {
    return (
        <div className="pl-3">
            <div className="font-bold py-3">
                Menu &gt;&gt;
            </div>
            <ul>
                <li>
                    <Link 
                        to="/" 
                        className="text-blue-500 py-3 border-t border-b block"
                        onClick={() => props.setShowMenu(false)}
                    >
                        Home
                    </Link>
                </li>
                {/* <li>
                    <Link 
                        to="/about" 
                        className="text-blue-500 py-3 border-b block"
                        onClick={() => props.setShowMenu(false)}
                    >
                        About
                    </Link>
                </li> */}
                <li>
                    <Link 
                        to="/cake" 
                        className="text-blue-500 py-3 border-b block"
                        onClick={() => props.setShowMenu(false)}
                    >
                        Flexbox CSS
                    </Link>
                    <Link 
                        to="/jammming" 
                        className="text-blue-500 py-3 border-b block"
                        onClick={() => props.setShowMenu(false)}
                    >
                        Spotify API with React
                    </Link>
                    <Link 
                        to="/boss" 
                        className="text-blue-500 py-3 border-b block"
                        onClick={() => props.setShowMenu(false)}
                    >
                        Boss Machine API with Node
                    </Link>
                </li>
            </ul>
       </div>
    )
}

export default NavigationMenu