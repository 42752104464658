import React from 'react';
import Navigation from './Navigation';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Header() {
    return(
    <header className="w-full border-b p-4 flex justify-between items-center">
        <span className="font-bold text-2xl py-1">
        <Link 
                to="/"
            >
                Victoria Blackburn
            </Link>
            
        </span>

        <div className="px-1">
            <FontAwesomeIcon icon={faGithub} className="mx-2"/>
            <a href="https://github.com/vacb" target="_blank" rel="noreferrer">GitHub</a>
        </div>
        <div>
            <FontAwesomeIcon icon={faLinkedin} className="mx-2"/>
            <a href="https://www.linkedin.com/in/victoria-blackburn/" target="_blank" rel="noreferrer">LinkedIn</a>
        </div>
        <Navigation />
    </header>
    )
}

export default Header;