import React from 'react';
import { Link } from 'react-router-dom';

function Back() {
    return (
        <div className="p-3">
          <Link 
                to="/" 
                className="text-blue-500 py-3 border-b block"
            >
                &lt;&lt; Back
            </Link>
        </div>
        
    )
}

export default Back;