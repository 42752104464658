import React, { useState } from 'react';
// FontAwesome Icon component, needs to take in a prop of the icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import {useTransition, animated} from 'react-spring';
import NavigationMenu from './NavigationMenu';

function Navigation() {
    // give component some state so that we can click on it
    // initialised with false
    // setShowMenu can be called to change value of showMenu
    const [showMenu, setShowMenu] = useState(false);

    const maskTransitions = useTransition(showMenu, null, {
        from: { position: 'absolute', opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    })


    const menuTransitions = useTransition(showMenu, null, {
        from: { opacity: 0, transform: 'translateX(-100%)' },
        enter: { opacity: 1, transform: 'translateX(-0%)' },
        leave: { opacity: 0, transform: 'translateX(-100%)' },
    })
    
    return (

        <nav>
        <span className="text-xl">
            <FontAwesomeIcon 
                icon={faBars}
                // !showMenu will toggle from true to false to true etc
                onClick={() => setShowMenu(!showMenu)}
            />
        </span>

        {/* // fade for empty black background */}
        {
            maskTransitions.map(({ item, key, props }) =>
                item && 
                <animated.div 
                    key={key} 
                    style={props}
                    className="bg-black-t-50 fixed top-0 left-0 w-full h-full z-50"
                    onClick={() => setShowMenu(false)}
                >
                </animated.div>)
        }

        {/* // fade for menu */}
        {
            menuTransitions.map(({ item, key, props }) =>
                item && 
                <animated.div 
                    key={key} 
                    style={props}
                    className="fixed bg-white top-0 left-0 w-1/2 h-full z-50 shadow p-3"
                >
                    <NavigationMenu setShowMenu={setShowMenu}/>
                </animated.div>)
        }
        
    </nav>
    )
}

export default Navigation;