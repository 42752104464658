import React from 'react';
import Screenshot from '../Components/Screenshot';
import Back from '../Components/Back';
import jammming1 from '../media/jammming-1.png';

function Jammming() {
    return (
        <div className="p-3">
            <h1 className="font-bold text-2xl mb-3 py-1">Spotify API project</h1>
            <h2 className="font-bold text-xl mb-3 py-1">General information</h2>
            <p className="py-1">
                For this project I had to build a React web application that connected to Spotify and allowed a user to search for tracks, add them to a playlist and then save that playlist to their account.
                I had to create and connect React class components as well as handle requests with the Spotify API.
            </p>
            <p className="py-1">
                Base HTML and CSS for the page were supplied by Codecademy.
            </p>
            <h2 className="font-bold text-xl mb-3 py-1">Technologies</h2>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">JavaScript</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">React</span>
            <h2 className="font-bold text-xl mb-3 py-1">Links</h2>
            <p className="py-1 pb-2">This project runs locally, so is not currently available for live preview.</p>
            <a className="inline-block bg-indigo-900 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2" href="https://github.com/vacb/codecademy-boss-machine" target="_blank" rel="noreferrer">View code on GitHub</a>
        
            <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">  
                <Screenshot src={jammming1} alt="jammming app screenshot"/>
            </div>

            <Back />
        </div>
    )
}

export default Jammming;