import React from 'react';
import Screenshot from '../Components/Screenshot';
import Back from '../Components/Back';
import cake1 from '../media/cake-1.png';
import cake2 from '../media/cake-2.png';

function Cake() {
    return (
        <div className="p-3">
            <h1 className="font-bold text-2xl mb-3 py-1">Flexbox project</h1>
            <h2 className="font-bold text-xl mb-3 py-1">General information</h2>
            <p className="py-1">
                The idea of this project was to create a simple web page for a company, putting into practice what I had learned so far about CSS flexbox functionality.
            </p>    
            <p className="py-1">
                This was build from scratch using HTML and CSS, using placeholder images from <a href="https://unsplash.com/" target="_blank" rel="noreferrer">Unsplash</a>.
            </p>
            <h2 className="font-bold text-xl mb-3 py-1">Technologies</h2>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">HTML</span>
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">CSS</span>
            <h2 className="font-bold text-xl mb-3 py-1">Links</h2>
            <a className="inline-block bg-indigo-900 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2" href="https://vacb.github.io/codecademy-flexbox/" target="_blank" rel="noreferrer">Live page</a>
            <a className="inline-block bg-indigo-900 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2" href="https://github.com/vacb/codecademy-flexbox" target="_blank" rel="noreferrer">View code on GitHub</a>
        
            <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">  
                <Screenshot src={cake1} alt="cake website screenshot"/>
                <Screenshot src={cake2} alt="cake website screenshot"/>
            </div>
        
            <Back />
      </div>
        
    )
}

export default Cake;