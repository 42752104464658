import React from 'react';

import Header from './Components/Header';
// import Footer from './Components/Footer';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Home from './Views/Home';
import About from './Views/About';
import Cake from './Views/Cake';
import Boss from './Views/Boss';
import Jammming from './Views/Jammming';

function App() {
  return (
    <div className="container mx-auto text-gray-500">
      <Router>

      <Header />
      
      <div className="p-3">
        <Switch>
          <Route exact path="/">
              <Home />
          </Route>
          <Route path="/about">
              <About />
          </Route>
          <Route path="/cake">
            <Cake />
          </Route>
          <Route path="/jammming">
            <Jammming />
          </Route>
          <Route path="/boss">
            <Boss />
          </Route>
        </Switch>
      </div>

      {/* <Footer /> */}

      </Router>
      
      
    </div>
    
    
  );
}

export default App;
