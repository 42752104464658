import React from 'react';
import cake from '../media/cake.png'
import bossMachine1 from '../media/boss-machine-1.png'
import jammming1 from '../media/jammming-1.png'
import { Link } from 'react-router-dom';

function Card(props) {
    return (
        <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">  
          
            <div className="max-w-sm rounded overflow-hidden shadow-lg">
                <img className="w-full" src={cake} alt="Cake" />
                <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">Flexbox CSS</div>
                        <p className="text-gray-700 text-base">
                            My first attempt at using CSS flexbox in a website for a pretend bakery.
                        </p>
                        <Link 
                            to="/cake" 
                            className="text-blue-500 py-3 border-b block"
                        >
                            More information &gt;&gt;
                        </Link>
                    </div>
                 <div className="px-6 pt-4 pb-2">
                    <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">HTML</span>
                    <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">CSS</span>
                     </div>
            </div>

            <div className="max-w-sm rounded overflow-hidden shadow-lg">
                <img className="w-full" src={jammming1} alt="Cake" />
                <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">Spotify API with React</div>
                        <p className="text-gray-700 text-base">
                            Building a React app that connects to the Spotify API to create and add playlists.
                        </p>
                        <Link 
                            to="/jammming" 
                            className="text-blue-500 py-3 border-b block"
                        >
                            More information &gt;&gt;
                        </Link>
                    </div>
                 <div className="px-6 pt-4 pb-2">
                    <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">JavaScript</span>
                    <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">React</span>
                </div>
            </div>

            <div className="max-w-sm rounded overflow-hidden shadow-lg">
                <img className="w-full" src={bossMachine1} alt="Boss Machine" />
                <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">Boss Machine API with Node</div>
                    <p className="text-gray-700 text-base">
                            Creating a server with Node.js and creating an API.
                        </p>
                        <Link 
                            to="/boss" 
                            className="text-blue-500 py-3 border-b block"
                        >
                            More information &gt;&gt;
                        </Link>
                    </div>
                 <div className="px-6 pt-4 pb-2">
                    <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">JavaScript</span>
                    <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Node.js</span>
                    <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Express</span>
                </div>
            </div>
    
        </div>
            
    )
}

export default Card;