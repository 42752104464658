import React from 'react';

function Screenshots(props) {
    return ( 
            <div className="max-w-sm rounded overflow-hidden shadow-lg">
                <img className="w-full" src={props.src} alt={props.alt} />
            </div>
    )
}

export default Screenshots;