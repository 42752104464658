import React from 'react';
import Card from '../Components/Card';


function Home() {
    return (
        <div>
            <h1 className="font-bold text-2xl py-1" >Portfolio in progress!</h1>
            <p className="py-1">
                Welcome to my portfolio page. I'm currently still working through my studies to become a Junior Full-stack Web Developer, and this site will grow as I learn, but in the meantime please take a look at some of the things I've been working on:
            </p>
            <Card />
        </div>
    )
}

export default Home;